export const $on = function(el: ParentNode, evt: string, sel: string, handler: EventListener) {
  el.addEventListener(evt, function(event) {
    let t = event.target as Element; // Better to error if not
    while (t && t !== this) {
      if (t.matches(sel)) {
        handler.call(t, event); 
      }
      // if (t.parentNode instanceof Element)
      t = t.parentNode as Element; // Again, rather get the error.
    }
  });
}

export const $closest = function(el: Element, sel: string): Element {
  if (el) {
    return el.matches(sel)
           ? el
           : (el.parentNode instanceof Element)
             ? $closest(el.parentNode, sel)
             : null;
  }
}

declare global {
  interface Window {
    $on: typeof $on;
    $closest: typeof $closest;
  }
}
window.$on = $on
window.$closest = $closest;
