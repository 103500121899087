import "@hotwired/turbo-rails"

import "../globals";

window.$on(document, 'click', '#cookienotice .accept, #cookienotice .ok', function(e) {
  e.preventDefault();
  const notice = document.querySelector("#cookienotice");
  notice.classList.add("closed");
  const date = new Date(Date.now() + 86400e3*365);
  const expdat = date.toUTCString();
  document.cookie="ttzacceptcookie=true; expires="+ expdat +"; path=/";
});


window.$on(document, 'click', '#event_pop_over .hide', function(e) {
  e.preventDefault();
  const notice = document.querySelector("#event_pop_over");
  notice.classList.add("closed");
  const date = new Date(Date.now() + 86400e3*7);
  const expdat = date.toUTCString();
  document.cookie="seen_event_alert=true; expires="+ expdat +"; path=/";
});
